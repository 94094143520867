import React from "react"
import { Link, graphql } from "gatsby"
import "../styles/app.css"
import SEO from "../components/seo"
import Footer from "../components/footer"
import * as logo from "../../static/codeck-logo.svg"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <React.Fragment>
        <img src={logo} className="logo" />
        <footer>© {new Date().getFullYear()} CoDeck Labs</footer>
      </React.Fragment>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
